<template>
  <MinimalWithSideBarLayout
    :progress-bar-current-value
    @back="emit('back')"
    @cancel="emit('cancel')"
  >
    <template #sidebar>
      <OrderlineRecapBlock :order-id :product-image :product-title />
    </template>

    <template #content>
      <div class="pb-80">
        <div
          v-for="(issue, index) in issues"
          :key="index"
          class="mt-24 first:mt-0"
        >
          <h2 class="heading-2">{{ issue.label }}</h2>

          <div class="mt-24 md:mt-12">
            <IssuesCheckboxes
              v-model="checkedIssues[index]"
              :current-step
              :customer-issue="issue.customerIssue"
              :siblings="issue.siblings"
            />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <RevStickyBar
        position="bottom"
        :snap-to-view-port="isStickyBarSnapToViewPort"
      >
        <div class="md:mx-auto md:max-w-640">
          <RevButton
            data-qa="diagnosis-continue-button"
            :disabled="!hasCheckedIssues"
            full-width="always"
            variant="primary"
            @click="onContinue"
          >
            {{ i18n(translations.continue) }}
          </RevButton>
        </div>
      </RevStickyBar>
    </template>
  </MinimalWithSideBarLayout>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { type IssueSibling } from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/issueListResponse'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useBreakpoint } from '@backmarket/utils/composables/useBreakpoint'
import { Breakpoint } from '@backmarket/utils/dom/getCurrentBreakpoint'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevStickyBar } from '@ds/components/StickyBar'

import OrderlineRecapBlock from '@/scopes/customer-care/common/components/OrderlineRecapBlock/OrderlineRecapBlock.vue'
import MinimalWithSideBarLayout from '@/scopes/customer-care/common/layout/MinimalWithSideBarLayout.vue'

import IssuesCheckboxes from '../IssuesCheckboxes/IssuesCheckboxes.vue'

import translations from './IssuesLayout.translations'
import type { IssuesLayoutProps } from './IssuesLayout.types'

const emit = defineEmits<{
  back: []
  cancel: []
  continue: [value: IssueSibling[]]
}>()

const props = defineProps<IssuesLayoutProps>()

const breakpoint = useBreakpoint()
const i18n = useI18n()

const checkedIssues = ref<string[][]>(Array.from(props.issues, () => []))

const hasCheckedIssues = computed(() => {
  if (isEmpty(checkedIssues.value)) {
    return false
  }

  return checkedIssues.value.every((checkedIssue) => checkedIssue.length >= 1)
})

const isStickyBarSnapToViewPort = computed(
  () => !(breakpoint.value >= Breakpoint.MD),
)

function onContinue() {
  const formatIssuesChecked = props.issues
    .map((issue, index) =>
      issue.siblings.filter((sibling) => {
        if (!isEmpty(checkedIssues.value[index])) {
          return checkedIssues.value[index].some((checkedIssue) =>
            sibling.customerIssue.includes(checkedIssue),
          )
        }

        return false
      }),
    )
    .flat()

  emit('continue', formatIssuesChecked)
}
</script>
